
import { Component, Vue } from "vue-property-decorator";
import { Getter, State } from "vuex-class";
import { Agent_agent as AgentNode } from "../gql-typings/Agent";
import { RootState } from "~/store";

@Component<SiteFooter>({})
export default class SiteFooter extends Vue {
	@State("viewer")
	readonly viewer!: RootState["viewer"];

	@Getter("agent")
	readonly agent!: AgentNode;

	@State("browsingSessionId")
	readonly browsingSessionId!: string | null;

	get target(): string {
		const {HOST_WEB} = this.$config;

		if (process.client && !window.URL) {
			return HOST_WEB;
		}

		const url = new URL(this.$route.fullPath || "/", HOST_WEB);

		if (this.browsingSessionId) {
			url.searchParams.set("session-id", this.browsingSessionId);
		} else if (this.agent) {
			url.searchParams.set("presented-by", this.agent.oid);
		}

		return url.toString();
	}
}
