
import { Component, Vue } from "vue-property-decorator";
import { Getter, State } from "vuex-class";
import { RootState } from "../store";
import SiteFooter from "../components/site-footer.vue";
import AgentBar from "../components/agent-bar.vue";
import { Location } from "vue-router"

import UnseenViewerNotificationsCountQuery from "@/apollo/UnseenViewerNotificationsCountQuery.graphql";

@Component<Header>({
	apollo: {
		unseenViewerNotificationsCount: {
			query: UnseenViewerNotificationsCountQuery,
			skip() {
				return !this.viewer;
			},
		}
	},

	components: {
		SiteFooter: SiteFooter as any,
		AgentBar,
	},
})
export default class Header extends Vue {
	@State("viewer") readonly viewer!: RootState["viewer"];
	@Getter("agent") readonly agent!: RootState["assignedAgent"];
	@State("isMobile") readonly isMobile!: RootState["isMobile"];

	unseenViewerNotificationsCount!: number;

	mounted() {
		if (this.isMobile) {
			setTimeout(() => this.$store.dispatch("setSeenAgentForAgentBar"), 1500);
		} else {
			this.$store.dispatch("setSeenAgentForAgentBar");
		}
	}

	get loginLocation(): Location {
		let redirect: string | undefined = this.$route.fullPath;

		if (
			this.$route.path.includes("login") ||
			this.$route.path.includes("logout") ||
			this.$route.path.includes("onboarding")
		) {
			redirect = undefined;
		}

		return {
			path: "/login",
			query: {
				redirect,
			},
		}
	}
}
