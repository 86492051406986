var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('header',{attrs:{"id":"site-header"}},[_c('div',{staticClass:"inner-wrapper"},[_c('div',{staticClass:"header-row"},[_c('client-only',[_c('transition',{attrs:{"name":"agent-fade"}},[(_vm.$store.getters.showAgentBar)?_c('agent-bar',{attrs:{"agent":_vm.agent}}):_vm._e()],1),_vm._v(" "),_c('template',{slot:"placeholder"},[_c('agent-bar',{attrs:{"agent":_vm.agent}})],1)],2),_vm._v(" "),_c('nav',{staticClass:"main-navigation"},[_c('nuxt-link',{staticClass:"navigation-link",attrs:{"id":"nav--home","to":{
					path: '/home',
					query: {
						'presented-by': _vm.agent && _vm.agent.oid,
					},
				},"exact":"","no-prefetch":""}},[_c('span',{staticClass:"text"},[_vm._v("Home")]),_vm._v(" "),_c('span',{staticClass:"text mobile"},[_vm._v("Home")])]),_vm._v(" "),_c('nuxt-link',{staticClass:"navigation-link",attrs:{"id":"nav--search","to":{
					path: '/listings',
					query: {
						'presented-by': _vm.agent && _vm.agent.oid,
					}
				},"exact":"","no-prefetch":""}},[_c('span',{staticClass:"text"},[_vm._v("Browse")]),_vm._v(" "),_c('span',{staticClass:"text mobile"},[_vm._v("Browse")])]),_vm._v(" "),(_vm.agent)?_c('nuxt-link',{staticClass:"navigation-link",attrs:{"id":"nav--agent","to":`/agents/${_vm.agent.oid}`,"exact":"","no-prefetch":""}},[_c('div',{staticClass:"image-holder",class:{has_picture: Boolean(_vm.agent.picture)}},[(_vm.agent.picture)?_c('img',{attrs:{"src":_vm.agent.picture.uri,"alt":`${_vm.agent.name}'s profile photo`}}):_c('div',{staticClass:"placeholder-image"})]),_vm._v(" "),_c('span',{staticClass:"text"},[_vm._v("Agent")]),_vm._v(" "),_c('span',{staticClass:"text mobile"},[_vm._v("Agent")])]):_vm._e(),_vm._v(" "),(_vm.viewer)?_c('nuxt-link',{staticClass:"navigation-link",attrs:{"id":"nav--account","to":"/profile/stuff","exact":"","no-prefetch":""}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.unseenViewerNotificationsCount),expression:"unseenViewerNotificationsCount"}],staticClass:"badge",class:{plus: _vm.unseenViewerNotificationsCount > 9}},[_c('span',[_vm._v(_vm._s(_vm.unseenViewerNotificationsCount > 9 ? `9+` : _vm.unseenViewerNotificationsCount))])]),_vm._v(" "),_c('span',{staticClass:"text"},[_vm._v("My Account")]),_vm._v(" "),_c('span',{staticClass:"text mobile"},[_vm._v("Account")])]):_c('nuxt-link',{staticClass:"navigation-link",attrs:{"id":"nav--account","to":_vm.loginLocation,"exact":"","no-prefetch":""}},[_c('span',{staticClass:"text"},[_vm._v("Sign up / Sign in")]),_vm._v(" "),_c('span',{staticClass:"text mobile"},[_vm._v("Sign in")])])],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }