var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('footer',{attrs:{"id":"site-footer"}},[_c('div',{staticClass:"inner-wrapper"},[_c('div',{staticClass:"footer-row"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"footer-links",attrs:{"data-nosnippet":""}},[(_vm.viewer)?_c('nuxt-link',{attrs:{"to":{
					path: '/get-app',
					query: {
						'presented-by': _vm.agent.oid,
					},
				}}},[_vm._v("Get the app")]):_vm._e(),_vm._v(" "),_c('nuxt-link',{attrs:{"to":"/contact","no-prefetch":""}},[_vm._v("Contact")]),_vm._v(" "),_c('nuxt-link',{attrs:{"to":"/legal","no-prefetch":""}},[_vm._v("Legal")])],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"copyright",attrs:{"data-nosnippet":""}},[_c('span',[_vm._v("© 2024 Listed Technologies Inc.")])])
}]

export { render, staticRenderFns }